<template>
  <div id="botpress-webchat"></div>
</template>

<script>
export default {
  name: 'BotPressChat',
  mounted() {
    const injectScript = document.createElement('script');
    injectScript.src = 'https://cdn.botpress.cloud/webchat/v2.2/inject.js';
    injectScript.async = true;
    document.head.appendChild(injectScript);

    injectScript.onload = () => {
      const configScript = document.createElement('script');
      configScript.src = 'https://files.bpcontent.cloud/2024/10/04/13/20241004132136-7F43R270.js';
      configScript.async = true;
      document.head.appendChild(configScript);


      configScript.onerror = () => {
        console.error('Error loading Botpress config.js');
      };
    };

    injectScript.onerror = () => {
      console.error('Error loading Botpress inject.js');
    };
  },
};
</script>